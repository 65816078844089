import request from '@/utils/request';

//列表
export function companyList(data) {
  return request({
    url : '/user/companyList',
    method : 'post',
    data : data
  })
}

//新增
export function createCompany(data) {
  return request({
    url : '/user/createCompany',
    method : 'post',
    data : data
  })
}
//修改
export function updateCompany(data) {
  return request({
    url :'/user/updateCompany',
    method : 'post',
    data : data
  })
}


//删除
export function deleteCompany(data) {
  return request({
    url : '/user/deleteUser',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteCompany(data) {
  return request({
    url : '/user/batchDeleteUser',
    method : 'post',
    data : data
  })
}

//重置密码
export function resetCompanyPass(data) {
  return request({
    url : '/user/resetUserPass',
    method : 'post',
    data : data
  })
}

//冻结账号
export function freezeCompany(data) {
  return request({
    url : '/user/freezeCompany',
    method : 'post',
    data : data
  })
}

//解冻账号
export function thawCompany(data) {
  return request({
    url : '/user/thawCompany',
    method : 'post',
    data : data
  })
}

//批量设置过期时间
export function batchAddEndTime(data) {
  return request({
    url : '/user/batchAddEndTime',
    method : 'post',
    data : data
  })
}
